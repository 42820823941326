
import {Vue} from 'vue-class-component';
import motoboy from "@/services/motoboy";

export default class HomeView extends Vue {
  motoboys: IMotoboy[] = [];

  mounted(): void {
    this.carregarMotoboys();
    motoboy.saveSignOffline();
  }

  async carregarMotoboys(): Promise<void> {
    try {
      let res = await motoboy.listBoys(navigator.onLine);
      if (Array.isArray(res)) {
        this.motoboys = res;
      }
    } catch (e) {
      console.log(e);
    }
  }

  selecionarMotoboy(motoboy: IMotoboy): void {
    this.$router.push({name: 'Entregas', params: {id: motoboy.id_fun.toString()}});
  }
}
